<template>
  <div class="table-container">
    <div class="table-content">
      <a-table :dataSource="props.dataSource" :columns="props.columns" :pagination="false" class="hidden-content" />
      <vue-seamless-scroll
        :data="props.dataSource"
        :class-option="optionSingleHeightTime"
        class="seamless-warp"
        ref="seamlessRef"
        :style="{ height: props.dataSource.length < props.scrollCount ? (props.dataSource.length*36+'px'): (props.scrollCount*36+'px')}"
      >
      <a-table :dataSource="props.dataSource" :columns="props.columns" :pagination="false" class="hidden-head"> 
        <template #bodyCell="data">
          <slot name="bodyCell" v-bind="data"></slot>
        </template>
      </a-table>
      </vue-seamless-scroll>
    </div>
  </div>
</template>
<script setup>
import vueSeamlessScroll from "vue-seamless-scroll/src";
import { ref, computed, defineProps, watch } from "vue";
const props = defineProps({
  tableTitle: {
    type: String,
    default: null
  },
  columns: {
    type: Array,
    default: (() => [])
  },
  dataSource: {
    type: Array,
    default: (() => [])
  },
  scrollCount: {
    type: Number,
    default: 3
  },
})
const seamlessRef = ref();
watch([() => props.dataSource,() => props.columns], (newValue, oldValue) => {
  if(JSON.stringify(newValue) != JSON.stringify(oldValue)){
    seamlessRef.value && seamlessRef.value.reset();
  }
}, { deep :true })
const optionSingleHeightTime = computed(() => {
  return {
    singleHeight: 36, // 单步运动停止的高度
    waitTime: 2000, // 单步运动停止的时间(默认值1000ms)*/
    step: 1, // 数值越大速度滚动越快
    hoverStop: true, // 是否开启鼠标悬停stop
    openWatch: true, // 开启数据实时监控刷新dom
    limitMoveNum: props.dataSource.length, // 开始无缝滚动的数据量
    direction: 1, // 0向下 1向上 2向左 3向右
    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
  };
});
</script>
<style scoped lang="less">
.table-container {
  width: 100%;
  font-size: 14px;
  .seamless-warp {
    width: 100%;
    overflow: hidden;
  } 
}

::v-deep .ant-table {
  background: transparent;
}
.hidden-content  ::v-deep .ant-table-tbody {
  display: none;
}
.hidden-head  ::v-deep .ant-table-thead {
  display: none;
}
::v-deep .ant-table-tbody > tr > td, ::v-deep .ant-table-thead > tr > th {
  padding: 0;
  border-bottom: 0;
  height: 36px;
  line-height: 36px;
  color: #fff;
  font-style: italic;
  letter-spacing: 0.35px;
}
::v-deep .ant-table-thead > tr > th {
  color: rgba(246,249,254,.6); 
  background: transparent;
  font-style: italic;
}
::v-deep .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0;
}
::v-deep .ant-table-tbody > tr.ant-table-row:hover > td, ::v-deep .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: transparent;
}
</style>