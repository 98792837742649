<template>
  <my-echarts :option="option" :style="{ width: props.width, height: props.height }"></my-echarts>
</template>

<script setup>
import { ref, watch, defineExpose } from "vue";
const props = defineProps({
  dataSource: {
    type: Array,
    default: () => [],
  },
  legendData: {
    type: String,
    default: '',
  },
  leftTitle: {
    type: String,
    default: "",
  },
  width: {
    type: String,
    default: "448px",
  },
  height: {
    type: String,
    default: "230px",
  },
  showNumber: {
    type: Boolean,
    default: true
  },
  changeOption:{
    type:Function,
  },
});
const option = ref({});
const initData = () => {
  option.value = {
    dataset: {
      source: props.dataSource,
    },
    grid: {
      bottom: "12%",
      left: "15%"
    },
    title: {
      show: false,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
      },
    },
    legend: {
      show: false,
      data: [props.legendData],
      right: "3%",
      top: "10%",
      itemWidth: 11,
      itemHeight: 11,
      textStyle: {
        color: "#ffffff",
        fontSize: 13,
      },
    },
    toolbox: {
      show: false,
    },
    xAxis: {
      type: "category",
      axisLine: {
        lineStyle: {
          // 横坐标线
          color: "rgba(255, 129, 109, 0.1)",
          width: 1,
        },
      },
      axisLabel: {
        textStyle: {
          // 横坐标字体
          color: "rgba(246, 249, 254, 0.8)",
          fontSize: 12,
          fontStyle: "italic",
        },
      },
    },
    yAxis: {
      name: props.leftTitle,
      nameTextStyle: {
        color: "#f6f9fe",
        fontSize: 12,
        fontStyle: "italic",
      },
      type: "value",
      scale: true,
      axisLine: {
        show: false,
      },
      splitNumber: 3,
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "rgba(119, 180, 255, .1)",
          width: 1,
          type: "dashed",
        },
      },
      axisLabel: {
        // 纵坐标数值
        fontSize: 14,
        // margin: 12,
        fontStyle: "italic",
        color: "#f6f9fe",
      },
      min: 0,
      boundaryGap: [0.2, 0.2],
    },
    series: [
      {
        name: props.legendData || '',
        type: "bar",
        barWidth: 16,
        barMinHeight: 3,//防止数据数值小显示不出来
        itemStyle: {
          normal: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#3EB2FF", //  0%  处的颜色
                },
                {
                  offset: 1,
                  color: "#2075FE", //  100%  处的颜色
                },
              ],
            },
          },
        },
        yAxisIndex: 0,
        label: {
          show: props.showNumber,
          position: 'top',
          color: '#fff',
          formatter: (params) => {
            return params.data.count
          }
        }
      },
    ],
  };
  props.changeOption && props.changeOption(option);
};
watch([() => props.dataSource, () => props.legendData], () => 
  { 
    initData()
  },
  { immediate: true, deep: true }
);
defineExpose({
  option,
});
</script>

<style scoped lang="less">
</style>