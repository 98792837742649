import { createI18n } from 'vue-i18n';
import cn from './cn';
import en from './en';

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('lang') || 'cn', // 优先从本地存储获取语言设置，如果没有则使用浏览器默认语言
  fallbackLocale: 'en', // 当前语言无法找到匹配的翻译时，使用的备选语言
  messages: {
    en,
    cn,
  },
});

export default i18n;