<template>
  <div class="languageMenu">
    <div class="languageBtn cn" :class="{ active: locale == 'cn' }" @click="changeLang('cn')">中文</div>
    <div class="languageBtn en" :class="{ active: locale == 'en' }" @click="changeLang('en')">English</div>
  </div>
</template>
<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';

  const { locale } = useI18n();
  const changeLang = (lang) => {
    locale.value = lang;
    localStorage.setItem('lang', lang); // 保存用户的语言选择到本地存储
  };
  defineExpose({
    changeLang,
  })
</script>

<style scoped lang="less">
  .languageMenu {
    position: fixed;
    top: 28px;
    right: 182px;
    z-index: 999;
    display: flex;
    cursor: pointer;
  }
  .languageBtn {
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid #164fd4;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }
  .cn {
    width: 80px;
  }
  .en {
    width: 94px;
  }
  .active {
    color: #fff;
    box-shadow: 0 0 10px #2075FE, /* 主内发光 */
    -1px -1px 5px rgba(32, 117, 254, 0.5), /* 左上侧内阴影 */
    1px 1px 5px rgba(32, 117, 254, 0.5); /* 右下侧内阴影 */
  }
</style>
