<template>
  <my-echarts :option="option" :style="{ width: props.width, height: props.height }"></my-echarts>
</template>

<script setup>
import { ref, watch, defineExpose } from "vue";
import { useI18n } from 'vue-i18n';

const { locale, t } = useI18n();
const props = defineProps({
  dataSource: {
    type: Array,
    default: () => []
  },
  legendData: {
    type: Array,
    default: () => []
  },
  leftTitle: {
    type: String,
    default: ''
  },
  unit: {
    type: String,
    default: ''
  },
  width: {
    type: String,
    default: '448px'
  },
  height: {
    type: String,
    default: '230px'
  }
})
const option = ref({});
const initData = () => {
  option.value = {
    dataset: {
      source: props.dataSource
    },
    grid: {
      bottom: "12%",
      left: '12%',
      right: '15%'
    },
    title: {
      show: false,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: "line", // 默认为直线，可选为：'line' | 'shadow'
      },
    },
    color: ["#3AADFF", "#40FFCB"],
    legend: {
      data: props.legendData,
      left: "center",
      top: "10%",
      itemWidth: 30,
      itemHeight: 11,
      textStyle: {
        color: "#F6F9FE",
        fontSize: 12,
        fontStyle: 'italic',
      },
    },
    toolbox: {
      show: false,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        axisLine: {
          lineStyle: { // 横坐标线
            color: "rgba(255, 129, 109, 0.1)",
            width: 1
          },
        },
        axisLabel: {
          textStyle: { // 横坐标字体
            color: "rgba(246, 249, 254, 0.8)",
            fontSize: 12,
            fontStyle: "italic",
          },
        },
      },
    ],
    yAxis: [
      {
        name: props.leftTitle,
        nameTextStyle: {
          color: "#f6f9fe",
          fontSize: 12,
          fontStyle: "italic",
        },
        type: "value",
        axisLine: {
          show: false,
        },
        splitNumber: 4,
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "rgba(119, 180, 255, .1)",
            width: 1,
            type: "dashed",
          },
        },
        axisLabel: {
          // 纵坐标数值
          fontSize: 14,
          // margin: 12,
          fontStyle: "italic",
          color: "#f6f9fe",
        },
        // scale: true,
      },
      {
        type: "value",
        axisLine: {
          show: false,
        },
        splitNumber: 4,
        axisTick: {
          show: false,
        },
        axisLabel: {
          // 纵坐标数值
          fontSize: 14,
          fontStyle: "italic",
          color: "#f6f9fe",
          formatter: "{value}" + props.unit,
        },
        splitLine: {
          // 纵坐标间隔线
          show: false,
          lineStyle: {
            color: "rgba(119, 180, 255, .1)",
            width: 1,
            type: "dashed",
          },
        },
        // scale: true,
      },
    ],
    series: [
      {
        name: props.legendData[0],
        type: "line",
        smooth: true,
        symbol: "none",
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              // 渐变颜色
              {
                offset: 0,
                color: "#3DB0FF",
              },
              {
                offset: 1,
                color: "transparent",
              },
            ],
            global: false,
          },
        },
        yAxisIndex: 0,
      },
      {
        name: props.legendData[1],
        type: "line",
        smooth: true,
        yAxisIndex: 1,
        color: "#40ffcb",
        symbol: "none",
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              // 渐变颜色
              {
                offset: 0,
                color: "#2BE4DB",
              },
              {
                offset: 1,
                color: "transparent",
              },
            ],
            global: false,
          },
        },
        z: 10,
      },
    ],
  };
  if(locale.value == 'en'){
    option.value.legend.left = '20%';
    option.value.legend.top = '3%';
  }
}
watch([() => props.dataSource,()=> props.dataSource.legendData], () => 
{
  initData();
}, { immediate: true, deep: true })
defineExpose({
  option
})
</script>

<style scoped lang="less">
</style>