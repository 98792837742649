<template>
  <my-echarts :option="option" :style="{ width: props.width, height: props.height }"></my-echarts>
</template>

<script setup>
import { ref, watch, defineExpose } from "vue";
const props = defineProps({
  dataSource: {
    type: Array,
    default: () => [],
  },
  legendData: {
    type: String,
    default: '',
  },
  leftTitle: {
    type: String,
    default: "",
  },
  width: {
    type: String,
    default: "833px",
  },
  height: {
    type: String,
    default: "230px",
  },
});
const option = ref({});
const initData = () => {
  option.value = {
    dataset: {
      source: props.dataSource,
    },
    grid: {
      bottom: "12%",
      left: '0',
      right: '0',
      top: '130px'
    },
    title: {
      show: false,
    },
    tooltip: {},
    legend: {
      show: false,
      data: [props.legendData],
      right: "3%",
      top: "10%",
      itemWidth: 11,
      itemHeight: 11,
      textStyle: {
        color: "#ffffff",
        fontSize: 13,
      },
    },
    toolbox: {
      show: false,
    },
    xAxis: {
      type: "category",
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        textStyle: {
          // 横坐标字体
          color: "rgba(246, 249, 254, 0.8)",
          fontSize: 12,
          fontStyle: "italic",
        },
      },
    },
    yAxis: {
      show: false,
      type: "value",
      scale: true,
    },
    series: [
      {
        name: props.legendData || '',
        type: "scatter",
        symbolSize: (param) => {
          //console.log(param)
          return param.rate / 2 + 70;
        },
        symbolOffset: [0, '-50%'],
        label: {
          normal: {
            show: true,
            formatter: (param) => {
              return param.count;
            },
            color: '#fff',
            textStyle: {
              fontSize: '12',
              fontWeight: '600'
            },
          },
        },
        itemStyle: {
          normal: {
            color: {
              type: 'radial',
              x: 0.5,
              y: 0.5,
              r: 0.5,
              colorStops: [{
                offset: 0, color: 'rgba(0, 0, 0, 0.1)' // 0% 处的颜色
              }, {
                offset: 0.8, color: 'rgba(34, 113, 255, 0.3)'
              }, {
                offset: 1, color: 'rgba(34, 113, 255, 1)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            },
            shadowColor: 'rgba(32, 117, 254, 0.5)',
            shadowBlur: 20,
            borderWidth: 0,
          },
          emphasis: {
            color: {
              type: 'radial',
              x: 0.5,
              y: 0.5,
              r: 0.5,
              colorStops: [{
                offset: 0, color: 'rgba(0, 0, 0, 0.1)' // 0% 处的颜色
              }, {
                offset: 0.8, color: 'rgba(64, 255, 203, 0.3)'
              }, {
                offset: 1, color: 'rgba(64, 255, 203, 1)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            },
            shadowColor: 'rgba(32, 117, 254, 0.5)',
            shadowBlur: 20,
            borderWidth: 0,
          }
        },
        yAxisIndex: 0,
      },
    ],
  };
};
watch([() => props.dataSource, () => props.legendData], () => 
  { 
    initData()
  },
  { immediate: true, deep: true }
);
defineExpose({
  option,
});
</script>

<style scoped lang="less">
</style>