<template>
  <my-echarts :option="option" :style="{ width: props.width, height: props.height }"></my-echarts>
</template>

<script setup>
import { ref, watch, defineExpose } from "vue";
const props = defineProps({
  dataSource: {
    type: Array,
    default: () => []
  },
  legendData: {
    type: Array,
    default: () => []
  },
  leftTitle: {
    type: String,
    default: ''
  },
  unit: {
    type: String,
    default: ''
  },
  width: {
    type: String,
    default: '448px'
  },
  height: {
    type: String,
    default: '230px'
  }
})
const option = ref({});
const initData = () => {
  option.value = {
    dataset: {
      source: props.dataSource
    },
    grid: {
      left: '14%',
      bottom: "12%",
    },
    title: {
      show: false,
    },
    tooltip: {
      formatter: (params) => {
        let str = params.data.title + '<br/>' + "<span class='tip-yellow business-scale'></span>" + props.legendData[2] + '&nbsp;&nbsp;'
          + params.data.value + '（' + props.unit + '）'
        return str;
      },
    },
    legend: {
      data: [
        {
          name: '',
          icon: 'circle',
        },
        {
          name: props.legendData[2],
          icon: 'circle',
        },
        {
          name: props.legendData[1],
          icon: 'circle',
        }
      ],
      right: "10%",
      top: "10%",
      itemWidth: 10,
      itemHeight: 10,
      textStyle: {
        color: "rgba(246,249,254,.8)",
        fontSize: 12,
        fontStyle: 'italic',
      },
    },
    toolbox: {
      show: false,
    },
    xAxis: [
      {
        type: "category",
        axisLine: {
          lineStyle: { // 横坐标线
            color: "rgba(255, 129, 109, 0.1)",
            width: 1
          },
        },
        axisLabel: {
          textStyle: { // 横坐标字体
            color: "rgba(246, 249, 254, 0.8)",
            fontSize: 12,
            fontStyle: "italic",
          },
        },
      },
    ],
    yAxis: [
      {
        name: props.leftTitle,
        nameTextStyle: {
          color: "#f6f9fe",
          fontSize: 12,
          fontStyle: 'italic'
        },
        type: "value",
        scale: true,
        axisLine: {
          show: false,
        },
        splitNumber: 3,
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "rgba(119, 180, 255, .1)",
            width: 1,
            type: "dashed",
          },
        },
        axisLabel: {
          // 纵坐标数值
          fontSize: 14,
          // margin: 12,
          fontStyle: "italic",
          color: "#f6f9fe",
        },
        min: 0,
        boundaryGap: [0.2, 0.2],
      },
      {
        type: "value",
        scale: true,
        axisLine: {
          show: false,
        },
        splitNumber: 3,
        axisTick: {
          show: false,
        },
        axisLabel: {
          // 纵坐标数值
          show: false,
          fontSize: 14,
          fontStyle: "italic",
          color: "#f6f9fe",
          formatter: "{value}" + props.unit,
        },
        splitLine: {
          // 纵坐标间隔线
          show: false,
          lineStyle: {
            color: "rgba(119, 180, 255, .1)",
            width: 1,
            type: "dashed",
          },
        },
        min: 0,
        boundaryGap: [0.2, 0.2],
      },
    ],
    series: [
      {
        name: props.legendData[0],
        type: "bar",
        barWidth: 16,
        stack: 'total',
        itemStyle: {
          normal: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#3EB2FF", //  0%  处的颜色
                },
                {
                  offset: 1,
                  color: "#2075FE", //  100%  处的颜色
                },
              ],
            },
          },
        },
        yAxisIndex: 0,
      },
      {
        name: props.legendData[1],
        yAxisIndex: 0,
        color: "#40ffcb",
        label: {
          show: false,
          normal: {
            position: "top",
            textStyle: {
              color: "#f6f9fe",
              fontStyle: "italic",
            },
            formatter(params) {
              return params.data.rate + props.unit
            }
          },
        },
        lineStyle: {
          color: "rgba(64,255,203,1)",
        },
        type: "line",
        z: 10,
      },
      {
        name: props.legendData[2],
        type: "bar",
        barWidth: 16,
        stack: "total",
        itemStyle: {
          normal: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#FFE25A", //  0%  处的颜色
                },
                {
                  offset: 1,
                  color: "#FFC157", //  100%  处的颜色
                },
              ],
            },
          },
        },
        yAxisIndex: 0,
        label: {
          show: true,
          textStyle: {
            color: "#f6f9fe",
            fontStyle: "italic",
          },
          position: 'top', // 顶部显示
          formatter: (params) => {
            return Number(params.data.count) + Number(params.data.value) + '（' + props.unit + '）'
          },
        },
      }
    ],
  };
}
watch([() => props.dataSource, () => props.unit], () => {
  initData();
}, { immediate: true, deep: true })
defineExpose({
  option
})
</script>

<style scoped lang="less"></style>