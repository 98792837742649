export default {
  lang: 'en',
  //------------------单位-------------------
  '单位：万元':'Unit: 10,000 CNY',
  '单位：台':'Unit: unit',
  '单位：人': 'Unit: people',
  '单位：元':'Unit: Yuan',
  //----------------- 首页 -----------------
  '商用车后市场聚合服务平台': 'Commercial Vehicle Aftermarket Aggregation Service Platform',
  '累计服务司机数': 'Total drivers served',
  '人': 'people',
  '累计服务车辆数': 'Total vehicles served',
  '台': 'units',
  '累计服务企业数': 'Total enterprises served',
  '家': 'companies',
  '累计业务规模': 'Total business scale',
  '万元': '10,000 CNY ',
  '单位：亿元': 'Unit: 100 million CNY',
  '亿': '100 million',
  '当年新增业务规模': 'New business scale this year',
  //----------------------------------------
  //----------------- 卡修 -----------------
  '门店总数': 'Total stores',
  '车队总数': 'Total fleets',
  '车辆总数': 'Total vehicles',
  '备件销售额': 'Spare parts sales amount',
  '备件库存金额': 'Spare Parts Value',
  '最近成交': 'Recent transactions',
  '备件库存分类': 'Parts Classification',
  '下单时间': 'Order time',
  '订单类型': 'Order type',
  '订单状态': 'OrderStatus',
  //----------------------------------------
  //----------------- 金融 -----------------
  '综合金融数据平台': 'Comprehensive Financial Data Platform',
  '业务规模': 'Business scale',
  '累计促销': 'Total promotion',
  '累计放款': 'Total loans',
  '亿元': '100 million CNY',
  '放款规模分布': 'Loan scale distribution',  
  '购车金融': 'Car purchase finance',
  '换车金融': 'Car Exchange finance',
  '修车金融': 'Car repair finance',
  '合作渠道': 'Cooperation channel',
  '合作渠道（家）': 'Cooperation channel (companies)',
  '促销车辆': 'Promotional vehicle',
  '单位：台': 'Unit: unit',
  '用车金融': 'Car use finance',
  '累计放款额度分布热力图': 'Total loan amount distribution Heat map',
  //----------------- 二手车 -----------------
  '二手商用车数据平台':'Used Commercial Vehicle Data Platform',
  '平台数据':'Platform data',
  '平台车源':'vehicle source',
  '平台车商':'Platform Car Trader',
  '本月车源':'Vehicle this month',
  '本月成交':'Transactions this month',
  '经销商渠道':'Dealer channel',
  '解放经销商入驻率':'FAW Jiefang Dealer Occupancy Rate',
  '评估数据':'Evaluation data',
  '本月新增':'New Added this month',
  '平台订单':'Platform order',
  '总成交':'Vehicles total',
  '车商分布':'Car Trader distribution',
  '经销商分布':'Dealer distribution',
  '车辆流向':'Vehicle flow',
  '全国车商覆盖率':'National Car Traders coverage rate',
  '经销商分布热力图':'Dealer distribution Heat map',
  '平台撮合数据':'Platform Matching data',
  '平台总交易额（亿元）':"Platform's Total Transaction Amount(100 million CNY)",
  '今日发布车源（台）':'Vehicles Released Today(units)',
  '今日中拍数（台）':'Successful Bids Today(units)',
  '今日实时交易额（万元）':"Today's real-time transaction amount(10,000 CNY)",
  '平台交易规模':'Platform transaction scale',
  '交易规模':'Transaction scale',
  '完成出口车辆':'Exported vehicles completed',
  '出口流向':'Export country distribution',
  '车商分布热力图':'Car Traders distribution Heat map',
  //----------------------------------------

  //----------------- 能源 -----------------
  '智慧能源数据平台': 'Smart Energy Data Platform',
  '能源渠道': 'Energy Channel',
  '经销商': 'Dealer',
  '月活跃': 'Monthly Active',
  '社会代理': 'Social Agent',
  '经销商渠道': 'Dealer Channel',
  '社会代理渠道': 'Social Agent Channel',
  '中石油加油站': 'CNPC gas station',
  '天然气加气站': 'Natural Gas refueling station',
  '石油': 'Oil',
  '天然气': 'Gas',
  '座': '',
  '加油站分布热力图': 'Gas station distribution Heat map',
  '供油TOP6省份': 'Oil supply TOP6 provinces',
  '单位：吨': 'Unit: ton',
  '服务规模': 'Service scale',
  '本月新增': 'Added this month',
  '服务客户': 'Customers served',
  '客户规模': 'Customer scale',
  //----------------------------------------

  //----------------- etc -----------------
  '数字通行平台':'Digital Transportation Platform',
  '单车日均通行费及环比增长情况':'Average Daily Toll per Vehicle and Month-on-Month Growth',
  '单车日均通行费':'Average Daily Toll per Vehicle',
  '环比上月增长率':'Month-on-month growth rate',
  '月度通行费变化趋势':'Monthly toll change trend',
  '通行费':'toll',
  '激活车辆信息':'Activation vehicle information',
  '单车日均通行费分布热力图':'Average Daily Toll per Vehicle Distribution Heat Map',
  '服务规模':'Service scale',
  '累计通行费（万元）':'Total tolls (10,000 CNY)',
  '累计服务台次':'Total vehicles served',
  '累计服务次数':'Total service times',
  '车辆类型占比情况':'Vehicle type proportion',
  '车牌颜色占比情况':'License Plate Colors Proportion',
  '车辆品牌占比情况':'Vehicle brand Proportion',
  '车牌号':'License plate',
  '激活日期':'Activation date',
  '激活省份':'Activation province',
  '激活来源':'Activation source',
};