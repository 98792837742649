<template>
  <a-config-provider :locale="locale" v-if="$route.name">
    <router-view v-if="['loginPage'].includes($route.name)" />
    <ScaleBox v-else>
      <div class="screen-container">
        <div class="back-area" @click="stop();$router.replace('/')">
          <img v-if="$route.name != 'home'" class="back-image" src="@/assets/svg/back.svg" alt="">
        </div>
        <languageSwitch ref="languageSwitch" v-show="false"></languageSwitch>
        <router-view v-slot="{ Component }">
          <!-- slide, scale -->
          <transition name="slide">
            <div :key="$route.path">
              <component :is="Component"></component>
            </div>
          </transition>
        </router-view>
      </div>
    </ScaleBox>
    <rightClickMenu :list="rightMenuList"></rightClickMenu>
  </a-config-provider>
</template>

<script>
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import languageSwitch from '@/i18n/languageSwitch.vue';
  import rightClickMenu from '@/components/rightClickMenu'
  import dayjs from 'dayjs';
  import 'dayjs/locale/zh-cn';
  import i18n from '@/i18n';
  import config from '@/config'

  dayjs.locale('zh-cn');
  export default {
    name: 'App',
    components: {
      languageSwitch,
      rightClickMenu
    },
    data() {
      return {
        locale: zhCN,
        playInterval: null,
        playIndex: 0,
        rightMenuList: [
          {
            label: '中文',
            click: () => {
              this.$refs.languageSwitch.changeLang('cn');
            },
            show: () => {
              return i18n.global.locale.value == 'en';
            },
            hr: true
          },
          {
            label: 'English',
            click: () => {
              this.$refs.languageSwitch.changeLang('en');
            },
            show: () => {
              return i18n.global.locale.value == 'cn';
            },
            hr: true
          },
          // {
          //   label: '播放(10秒切换)',
          //   click: () => {
          //     this.play(10);
          //   },
          //   show: () => {
          //     return !this.playInterval && config.isDev;
          //   }
          // },
          {
            label: '播放(30秒切换)',
            click: () => {
              this.play(30);
            },
            show: () => {
              return !this.playInterval;
            }
          },
          {
            label: '播放(60秒切换)',
            click: () => {
              this.play(60);
            },
            show: () => {
              return !this.playInterval;
            },
            hr: true
          },
          {
            label: '停止播放(Esc)',
            click: () => {
              this.stop();
            },
            show: () => {
              return !!this.playInterval;
            },
            hr: true
          },
          {
            label: '首页',
            click: () => {
              this.$router.replace('/');
              this.stop();
            },
            show: () => {
              return this.$route.name != 'home';
            }
          },
          {
            label: '综合金融页',
            click: () => {
              this.$router.replace('/finance');
              this.stop();
            },
            show: () => {
              return this.$route.name != 'finance';
            }
          },
          {
            label: '二手商用车页',
            click: () => {
              this.$router.replace('/vehicle');
              this.stop();
            },
            show: () => {
              return this.$route.name != 'vehicle';
            }
          },
          {
            label: '数字通行页',
            click: () => {
              this.$router.replace('/transit');
              this.stop();
            },
            show: () => {
              return this.$route.name != 'transit';
            }
          },
          {
            label: '智慧能源页',
            click: () => {
              this.$router.replace('/energy');
              this.stop();
            },
            show: () => {
              return this.$route.name != 'energy';
            }
          }
        ]
      };
    },
    mounted() {
      const handleEscapeKey = (event) => {
        if (event.key === 'Escape') {
          this.stop();
        }
      };
      // 在挂载应用之前添加键盘事件监听器
      window.addEventListener('keydown', handleEscapeKey);
    },
    methods: {
      play(time = 60) {
        this.playInterval && window.clearInterval(this.playInterval);
        const playList = ['/', '/finance', '/vehicle', '/transit', '/energy'];
        this.playIndex = playList.indexOf(`/${this.$route.name}`);
        if (this.playIndex == -1) {
          this.playIndex = 0;
        } else {
          this.playIndex++;
        }
        console.log(this.playIndex)
        this.playInterval = setInterval(() => {
          this.$router.replace(playList[this.playIndex]);
          if ((this.playIndex + 2) > playList.length) {
            this.playIndex = 0;
          } else {
            this.playIndex++;
          }
        }, time * 1000);
      },
      stop() {
        this.playInterval && window.clearInterval(this.playInterval);
        this.playInterval = null;
        //this.$router.replace('/');
      }
    }
  }
</script>

<style>
.back-area {
  width: 240px;
  height: 80px;
  position: absolute;
  cursor: pointer;
  z-index: 9999;
}
.back-image {
  position: relative;
  width: 24px; 
  height: 24px;
  left: 180px;
  top: 34px;
}

/* 左右滑动动画 */
.slide-enter-active {
  transition: all 0.3s ease-out;
}
.slide-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

/* 缩放动画 */
.scale-enter-active, .scale-leave-active {
  transition: transform 0.5s;
}
.scale-enter, .scale-leave-to {
  transform: scale(0);
}
</style>
