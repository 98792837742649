const ACCESS_TOKEN = 'ACCESS_TOKEN';
const PROJECT_NAME = 'demo';
import * as CryptoJS from 'crypto-js'

export const getToken = () => localStorage.getItem(ACCESS_TOKEN);

export const setToken = token => localStorage.setItem(ACCESS_TOKEN, token);

const delHtmlTag = str => {
  return (str || '').replace(/<[^>]+>/g, '');
}
//转化表单项的placeholder 根据type类型拼接title
export const setPlaceholder = (type, title) => {
  if (['my-select'].indexOf(type) != -1) {
    return `全部${ delHtmlTag(title) }`;
  } else if (['my-dateToDate', 'my-timeToTime', 'date-dimension'].indexOf(type) != -1) {
    return Array.isArray(title) ? [`${title[0]}`, `${title[1]}`] : [`${title}`, `${title}`];
  } else {
    return `全部${ delHtmlTag(title) }`;
  }
}

export const regExpMap = new Map
  ([
    ['联系电话', /^1[23456789]\d{9}$/],//根据工信部2019年最新公布的手机号段
    ['座机电话', /\d{3}-\d{8}|\d{4}-\d{7}/],//如: 0341-86091234
    ['身份证', /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}([0-9]|x|X)$/],
    ['帐号', /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/],//字母开头，允许5-16字节，允许字母数字下划线组合
    ['纯中文', /^[\u4E00-\u9FA5]+$/],
    ['小数', /^\d+\.\d+$/],
    ['数字', /^\d{1,}$/],
    ['QQ号', /^[1-9][0-9]{4,10}$/],
    ['微信号', /^[a-zA-Z][-_a-zA-Z0-9]{5,19}$/],
    ['纯英文', /^[a-zA-Z]+$/],
    ['密码强度', /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/],//最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符
    ['URL', /^((https?|ftp|file):\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/\w\.-]*)*\/?/],
    ['IPv4地址', /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/],
    ['16进制颜色', /^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/],
    ['邮政编码', /^(0[1-7]|1[0-356]|2[0-7]|3[0-6]|4[0-7]|5[1-7]|6[1-7]|7[0-5]|8[013-6])\d{4}$/],
    ['Email', /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/],
    ['isImg', /.(png|jpg|gif|jpeg|webp)$/],
    ['isVideo', /.(mp4|ogg|webm)$/],
  ]);

/**
 * 验证生成器
 * @param {String} key 
 * @param {Boolean} required
 * @param {String} message 
 */
export const validateProvider = (key, required = false, message = "") => {
  let regExp = regExpMap.get(key);
  if (!regExp) throw new Error("没有找到对应的正则规则");

  const validator = (rule, value, callback) => {
    if (!value || regExp.test(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject(message ? message : `请输入正确的${key}!`);
    }
  }
  return [{ validator }, { required }];
}

/**
 * 深拷贝
 * @param {*} obj 要拷贝的对象
 */
const deepClone = (obj, hash = new WeakMap()) => { //递归拷贝
  if (obj instanceof RegExp) return new RegExp(obj);
  if (obj instanceof Date) return new Date(obj);
  if (obj === null || typeof obj !== 'object') {
    //如果不是复杂数据类型，直接返回
    return obj;
  }
  if (hash.has(obj)) {
    return hash.get(obj);
  }
  /**
   * 如果obj是数组，那么 obj.constructor 是 [Function: Array]
   * 如果obj是对象，那么 obj.constructor 是 [Function: Object]
   */
  let t = new obj.constructor();
  hash.set(obj, t);
  for (let key in obj) {
    //如果 obj[key] 是复杂数据类型，递归
    if (obj.hasOwnProperty(key)) {//是否是自身的属性
      if (obj[key] && typeof obj[key] === 'object') {
        t[key] = deepClone(obj[key], hash);
      } else {
        t[key] = obj[key];
      }
    }
  }
  return t;
}

/**
 * @description 获取某个对象下的属性，用于通过类似'a.b.c'的形式去获取一个对象的的属性的形式
 * @param {object} obj 对象
 * @param {string} key 需要获取的属性字段
 * @returns {*}
 */
export function getProperty(obj, key) {
  if (!obj) {
    return
  }
  if (typeof key !== 'string' || key === '') {
    return ''
  }
  if (key.indexOf('.') !== -1) {
    const keys = key.split('.')
    let firstObj = obj[keys[0]] || {}

    for (let i = 1; i < keys.length; i++) {
      if (firstObj) {
        firstObj = firstObj[keys[i]]
      }
    }
    return firstObj
  }
  return obj[key]
}

/**
 * @description 设置对象的属性值，如果'a.b.c'的形式进行设置
 * @param {object} obj 对象
 * @param {string} key 需要设置的属性
 * @param {string} value 设置的值
 */
export function setProperty(obj, key, value) {
  if (!obj) {
    return
  }
  // 递归赋值
  const inFn = function (_obj, keys, v) {
    // 最后一个属性key
    if (keys.length === 1) {
      _obj[keys[0]] = v
      return
    }
    // 0~length-1个key
    while (keys.length > 1) {
      const k = keys[0]
      if (!_obj[k] || (typeof _obj[k] !== 'object')) {
        _obj[k] = {}
      }
      const key = keys.shift()
      // 自调用判断是否存在属性，不存在则自动创建对象
      inFn(_obj[k], keys, v)
    }
  }

  if (typeof key !== 'string' || key === '') {

  } else if (key.indexOf('.') !== -1) { // 支持多层级赋值操作
    const keys = key.split('.')
    inFn(obj, keys, value)
  } else {
    obj[key] = value
  }
}

//存储用户信息
export const setUserInfo = data => {
  if (!data || typeof data != 'object') {
    return;
  }
  localStorage.setItem(`${PROJECT_NAME}_userInfo`, JSON.stringify(data));
}

//获取用户信息
export const getUserInfo = key => {
  let userInfo = JSON.parse(localStorage.getItem(`${PROJECT_NAME}_userInfo`) || '{}');
  if (key) {
    return userInfo[key];
  }
  return userInfo;
}

//时间格式化
import dayjs from 'dayjs';
export const dateFormat = (time, format = 'YYYY/MM/DD') => {
  if (!time) {
    return '';
  }
  return dayjs(time).format(format);
}

import { city } from '@/utils/city';
//获取搜索条件模板
export const getSearch = (keys = [], obj = {}) => {
  const searchList = {
    dateDimension: { type: 'date-dimension', model: ['startTime', 'endTime', 'dimension'], label: ['开始时间', '结束时间'] },
    date: { type: 'date-dimension', model: ['startTime', 'endTime', 'dimension'], label: ['开始时间', '结束时间'], attrs: { showDimension: false } },
    city: {
      type: 'my-select',
      //model: ['provinceCode', 'cityCode'],
      model: 'provinceCode',
      label: '所在地区',
      attrs: {
        options: city, fieldNames: { label: 'name', value: 'code' }
      }
    },
    identityType: { type: 'my-dic', model: 'identityType', label: '身份类型', attrs: { name: 'forj_data_identity_type' } },
    transactionType: { type: 'my-dic', model: 'transactionType', label: '车源类型', attrs: { name: 'forj_data_transaction_type' } },
    personSource: { type: 'my-dic', model: 'personSource', label: '来源', attrs: { name: 'forj_data_person_target' } },
    trademark: { type: 'my-select', model: 'trademark', label: '品牌', attrs: { options: [{ label: '一汽解放', value: '一汽解放' }] } },
    ...obj,
  }
  const search = [];
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    if (typeof key == 'object') {
      search.push({ ...searchList[key.name], ...key });
    } else {
      search.push(searchList[keys[i]]);
    }
  }
  return search;
}

export const getParameterMap = () => {
  let parameters = window.location.search;
  if (parameters.indexOf("?") == -1) return null;
  let map = new Map;
  let strs = parameters.substr(1).split("&");
  for (let i = 0; i < strs.length; i++) {
      let str = strs[i].split("=");
      map.set(str[0], str[1]);
  }
  return map;
}

export const encryptData = (params) => {
  let key = 'pigxpigxpigxpigx';
  const encrypt = CryptoJS.AES.encrypt(params, key, {
      iv: key, 
      mode: CryptoJS.mode.CBC, 
      padding: CryptoJS.pad.ZeroPadding 
    }).toString();
  return encrypt;
}

export const decryptData = (encryptedParams) => {
  let key = 'pigxpigxpigxpigx';
  const decrypted = CryptoJS.AES.decrypt(encryptedParams, key, {
    iv: key, 
    mode: CryptoJS.mode.CBC, 
    padding: CryptoJS.pad.ZeroPadding 
  }).toString(CryptoJS.enc.Utf8);
  return decrypted;
}

export const init = {
  install: function (Vue) {
    //Vue.config.globalProperties.xxx = xxx;
  }
}