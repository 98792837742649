export default {
  lang: 'cn',
  //------------------单位-------------------
  '单位：万元':'单位：万元',
  '单位：台':'单位：台',
  '单位：人': '单位：人',
  '单位：元':'单位：元',
  //----------------- 首页 -----------------
  '商用车后市场聚合服务平台': '商用车后市场聚合服务平台',
  '累计服务司机数': '累计服务司机数',
  '人': '人',
  '累计服务车辆数': '累计服务车辆数',
  '台': '台',
  '累计服务企业数': '累计服务企业数',
  '家': '家',
  '累计业务规模': '累计业务规模',
  '万元': '万元',
  '单位：亿元': '单位：亿元',
  '亿': '亿',
  '当年新增业务规模': '当年新增业务规模',
  //----------------------------------------
  //----------------- 卡修 -----------------
  '门店总数': '门店总数',
  '车队总数': '车队总数',
  '车辆总数': '车辆总数',
  '备件销售额': '备件销售额',
  '备件库存金额': '备件库存金额',
  '最近成交': '最近成交',
  '备件库存分类': '备件库存分类',
  '下单时间': '下单时间',
  '订单类型': '订单类型',
  '订单状态': '订单状态',
  //----------------------------------------
  //----------------- 金融 -----------------
  '综合金融数据平台': '综合金融数据平台',
  '业务规模': '业务规模',
  '累计促销': '累计促销',
  '累计放款': '累计放款',
  '亿元': '亿元',
  '放款规模分布': '放款规模分布',  
  '购车金融': '购车金融',
  '换车金融': '换车金融',
  '修车金融': '修车金融',
  '单位：万元': '单位：万元',
  '合作渠道': '合作渠道',
  '合作渠道（家）': '合作渠道（家）',
  '促销车辆': '促销车辆',
  '单位：台': '单位：台',
  '用车金融': '用车金融',
  '累计放款额度分布热力图': '累计放款额度分布热力图',
  //----------------- 二手车 -----------------
  '二手商用车数据平台':'二手商用车数据平台',
  '平台数据':'平台数据',
  '平台车源':'平台车源',
  '平台车商':'平台车商',
  '本月车源':'本月车源',
  '本月成交':'本月成交',
  '经销商渠道':'经销商渠道',
  '解放经销商入驻率':'解放经销商入驻率',
  '评估数据':'评估数据',
  '本月新增':'本月新增',
  '平台订单':'平台订单',
  '总成交':'总成交',
  '车商分布':'车商分布',
  '经销商分布':'经销商分布',
  '车辆流向':'车辆流向',
  '全国车商覆盖率':'全国车商覆盖率',
  '经销商分布热力图':'经销商分布热力图',
  '平台撮合数据':'平台撮合数据',
  '平台总交易额（亿元）':'平台总交易额（亿元）',
  '今日发布车源（台）':'今日发布车源（台）',
  '今日中拍数（台）':'今日中拍数（台）',
  '今日实时交易额（万元）':'今日实时交易额（万元）',
  '平台交易规模':'平台交易规模',
  '交易规模':'交易规模',
  '完成出口车辆':'完成出口车辆',
  '出口流向':'出口流向',
  '车商分布热力图':'车商分布热力图',
  //----------------------------------------

  //----------------- 能源 -----------------
  '智慧能源数据平台': '智慧能源数据平台',
  '能源渠道': '能源渠道',
  '经销商': '经销商',
  '月活跃': '月活跃',
  '社会代理': '社会代理',
  '经销商渠道': '经销商渠道',
  '社会代理渠道': '社会代理渠道',
  '中石油加油站': '中石油加油站',
  '天然气加气站': '天然气加气站',
  '石油': '石油',
  '天然气': '天然气',
  '座': '座',
  '加油站分布热力图': '加油站分布热力图',
  '供油TOP6省份': '供油TOP6省份',
  '单位：吨': '单位：吨',
  '服务规模': '服务规模',
  '本月新增': '本月新增',
  '服务客户': '服务客户',
  '客户规模': '客户规模',
  //----------------------------------------
  
  //----------------- etc -----------------
  '数字通行平台':'数字通行平台',
  '单车日均通行费及环比增长情况':'单车日均通行费及环比增长情况',
  '单车日均通行费':'单车日均通行费',
  '环比上月增长率':'环比上月增长率',
  '月度通行费变化趋势':'月度通行费变化趋势',
  '通行费':'通行费',
  '激活车辆信息':'激活车辆信息',
  '单车日均通行费分布热力图':'单车日均通行费分布热力图',
  '服务规模':'服务规模',
  '累计通行费（万元）':'累计通行费（万元）',
  '累计服务台次':'累计服务台次',
  '累计服务次数':'累计服务次数',
  '车辆类型占比情况':'车辆类型占比情况',
  '车牌颜色占比情况':'车牌颜色占比情况',
  '车辆品牌占比情况':'车辆品牌占比情况',
  '车牌号':'车牌号',
  '激活日期':'激活日期',
  '激活省份':'激活省份',
  '激活来源':'激活来源',
};