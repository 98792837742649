<template>
  <div>
    <!-- 隐藏菜单的遮罩层，用于点击关闭菜单 -->
    <div v-if="visible" class="menu-overlay" @click="hideMenu"></div>

    <!-- 右键菜单 -->
    <div v-if="visible" class="menu" :style="{ top: menuTop + 'px', left: menuLeft + 'px' }">
      <slot>
        <ul>
          <li v-for="(item, index) in list" :class="{ 'hr': !!item.hr }" @click="itemClick(item)" v-show="!item.show || item.show()" :key="index">{{ item.label || `按钮${index}` }}</li>
        </ul>
      </slot>
    </div>
  </div>
</template>

<script setup>
  import { ref, defineProps, onMounted, onUnmounted } from 'vue';
  const props = defineProps({
    list: {
      type: Object,
      dafault: () => ([]),
    },
  });
  const visible = ref(false);
  const menuTop = ref(0);
  const menuLeft = ref(0);
  let menuEl = null;

  const showMenu = (event) => {
    // 阻止默认的右击菜单
    event.preventDefault();
    // 更新菜单位置
    menuTop.value = event.clientY;
    menuLeft.value = event.clientX;
    let height = window.innerHeight - event.clientY;
    if(height < 302){
      menuTop.value = event.clientY - 302;
    }
    // 显示菜单
    visible.value = true;

    // 如果需要，可以在这里添加额外的逻辑来决定是否显示菜单
  };

  const hideMenu = () => {
    visible.value = false;
  };

  const handleClickOutside = (event) => {
    // 如果点击发生在菜单外部，则隐藏菜单
    // 注意：这里可能需要额外的逻辑来防止点击菜单项时隐藏菜单
    if (menuEl && !menuEl.contains(event.target)) {
      hideMenu();
    }
  };

  const itemClick = (item) => {
    item.click && item.click();
    hideMenu();
  }

  onMounted(() => {
    // 在 body 上添加右击事件监听器
    document.body.addEventListener('contextmenu', showMenu);
    // 添加点击事件监听器以处理点击菜单外部的情况
    document.body.addEventListener('click', handleClickOutside);

    // 在下一个 DOM 更新周期后获取菜单元素的引用
    const nextTick = () => {
      menuEl = document.querySelector('.menu'); // 注意：这里可能需要更具体的选择器来避免全局查询
    };

    // 由于 setup 中没有 $nextTick，我们可以使用 setTimeout 模拟（不推荐，但可行）
    // 或者确保在菜单实际渲染到 DOM 后再设置 menuEl（可能需要额外的逻辑）
    // 这里为了简化，我们直接设置，但请注意这可能在某些情况下不起作用
    nextTick();

    // 更好的做法是使用一个 ref 引用菜单元素，并在模板中绑定它，但这样需要改变模板结构
  });

  onUnmounted(() => {
    // 在组件卸载时移除事件监听器
    document.body.removeEventListener('contextmenu', showMenu);
    document.body.removeEventListener('click', handleClickOutside);
  });

</script>

<style scoped>
  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9998; /* 确保遮罩层在菜单之下 */
  }

  .menu {
    position: absolute;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid #000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 5px;
    color: #fff;
  }

    .menu ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .menu li {
      padding: 8px 12px;
      cursor: pointer;
    }

      .menu li:hover {
        background-color: #555;
      }
    .menu .hr {
      border-bottom: 1px solid #ccc;
      margin-bottom: 6px;
    }
</style>